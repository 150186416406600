import React, { Fragment } from 'react';
import moment from 'moment';

function Home(props) {
    return (
        <Fragment>
            <div className="appointment_upper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="header_main d-md-flex justify-content-between align-items-center">
                                <a href="/"><img src="/images/logo.png" alt="" /></a>
                                {/* <div className="contact_info">
                                    <h6>Contact Us<span><img src="/images/send_icon.png" alt="" /> support@fedbenefitspecialists.com</span></h6>
                                </div> */}
                            </div>
                            <div className="hero_text">
                                <h1 className="mb-3">FEDERAL BENEFITS & <br />
                                    RETIREMENT INCOME
                                </h1>
                                {/* <ul>
                                    <li><a href="#">Federal employees</a></li>
                                    <li><a href="#">Postal employees</a></li>
                                    <li><a href="#">State/municipal employees</a></li>
                                    <li><a href="#">Teachers</a></li>
                                </ul> */}
                                <a className="mt-3 setup_button" href="/setup-an-appointment">Schedule a Free Consultation</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our_services_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>ASK QUESTIONS ABOUT:</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <h3>Benefits</h3>
                            <ul>
                                <li><img src="/images/tick_icon.png" alt="" /> FERS Pension</li>
                                <li><img src="/images/tick_icon.png" alt="" /> Social Security</li>
                                <li><img src="/images/tick_icon.png" alt="" /> Spousal Benefit</li>
                                <li><img src="/images/tick_icon.png" alt="" /> FERS Supplement </li>
                                {/* <li><img src="/images/tick_icon.png" alt="" /> Generate tax-free income</li> */}
                            </ul>
                        </div>
                        <div className="col-md-5">
                            <h3>Thrift Savings Plan</h3>
                            <ul>
                                <li><img src="/images/tick_icon.png" alt="" /> TSP Modernization Act</li>
                                <li><img src="/images/tick_icon.png" alt="" /> SECURE Act</li>
                                {/* <li><img src="/images/tick_icon.png" alt="" /> Individual Retirement Accounts (IRA)</li>
                                <li><img src="/images/tick_icon.png" alt="" /> 401k</li>
                                <li><img src="/images/tick_icon.png" alt="" /> 403b</li> */}
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3 className="mx-4">Insurance </h3>
                            <ul className="mx-4">
                                <li><img src="/images/tick_icon.png" alt="" /> FEGLI (Life Insurance)</li>
                                <li><img src="/images/tick_icon.png" alt="" /> Long Term Care</li>
                                <li><img src="/images/tick_icon.png" alt="" /> Disability</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h6>Copyright © {moment().year()}. Benefit Specialists, LLC. All Rights Reserved.</h6>
                            <p>Benefit Specialists, LLC is not affiliated with, endorsed or sponsored by any federal agency. We are a marketing company only and we are not a financial advisor, broker dealer, investment advisory firm, insurance underwriter/carrier, insurance agency or a CPA. If you set up an appointment, the person you will be speaking with is not employed by us or by any federal agency. Further, by providing your contact information and setting up an appointment, you agree to our terms of service at <b><a href="https://fedbenefitspecialists.com/terms-of-use" style={{color:'white'}}>https://fedbenefitspecialists.com/terms-of-use</a></b>.</p>
                            <h6> <a href="/terms-of-use">Terms of Use</a> | <a href="/privacy-policy">Privacy Policy</a> | Contact Us: support@fedbenefitspecialists.com</h6>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Home;