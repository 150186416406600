import React, { Fragment } from 'react';
import moment from 'moment';

function Error404(props) {
    return (
        <Fragment>
            <div className="appointment_upper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <div className="header_main d-md-flex justify-content-between align-items-center">
                            <a href="/"><img src="/images/logo.png" alt=""/></a>
                            {/* <div className="contact_info">
                                <h6>Contact Us<span><img src="/images/send_icon.png" alt=""/> support@fedbenefitspecialists.com</span></h6>
                            </div> */}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our_services_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="mb-0 text-primar">404</h2>
                            <h2 className="mt-0">Page not found</h2>
                            <hr className="w-25" />
                            <p className="text-secondary">The link is broken or the page has been moved.</p>
                            <a className="btn btn-outline-primary" href="/">Back to Home</a>
                        </div>
                    </div>
                    <div className="row"></div>
                </div>
            </div>
            <div className="footer_main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h6>Copyright © {moment().year()}. Benefit Specialists, LLC. All Rights Reserved.</h6>
                            <p>Benefit Specialists, LLC is not affiliated with, endorsed or sponsored by any federal agency. We are a marketing company only and we are not a financial advisor, broker dealer, investment advisory firm, insurance underwriter/carrier, insurance agency or a CPA. If you set up an appointment, the person you will be speaking with is not employed by us or by any federal agency. Further, by providing your contact information and setting up an appointment, you agree to our terms of service at <b><a href="https://fedbenefitspecialists.com/terms-of-use" style={{color:'white'}}>https://fedbenefitspecialists.com/terms-of-use</a></b>.</p>
                            <h6> <a href="/terms-of-use">Terms of Use</a> | <a href="/privacy-policy">Privacy Policy</a> | Contact Us: support@fedbenefitspecialists.com</h6>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Error404;