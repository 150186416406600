import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PublicIcon from '@mui/icons-material/Public';
import { StyleSelectBox } from '../../Themes';
import moment from 'moment';
import constant from '../../utils/constant';

export default function AppointmentDetail({
  step, setStep,
  dateSelected,
  timezoneSelected,
  timeSelected,
  isDesktop, isTablet, isMobile
}) {
  const classes = StyleSelectBox();

  const st = { 
    display: "flex",
    marginTop:'20px',
    justifyContent: isMobile ? 'center' : 'start',
    alignContent:"center",
    color:"#595959",
    fontStyle: "normal"
  }

  const DetailsView = () => {
    return (
      <Box>
        <Box sx={{display:'flex', justifyContent: isMobile ? 'center' : 'start', alignItems:'center', gap:1}}>
          <Box sx={st} >
            <WatchLaterIcon color={"primary"}/>
            <Typography>
              &nbsp;30 min
            </Typography>
          </Box>
          <Box sx={st}>
            <PhoneInTalkIcon color={"primary"}/>
            <Typography>
              &nbsp;Phone Call
            </Typography>
          </Box>
        </Box>
        {
          dateSelected && 
          <Box sx={st}>
            <CalendarMonthIcon color={"success"}/>
            <Typography>
              &nbsp;
              { moment(dateSelected).format('MMM D, YYYY') }
              {timeSelected ? " at " + constant.timeDisplayArray[timeSelected]:""}
            </Typography>
          </Box>
        }
        
        {
          timezoneSelected && 
          <Box sx={st}>
            <PublicIcon color={"success"}/>
            <Typography>
              &nbsp;{timezoneSelected}
            </Typography>
          </Box>
        }
      </Box>
    )
  }

  return (
    <div>
      <Box sx={{p: 3, position:'relative'}}>
        {
        (step === 1 || step === 2) && 
        <IconButton className={isMobile ? classes.arrowIconStyleMobile : classes.arrowIconStyle} onClick={()=>{
          setStep(step-1)
        }} color={"primary"} >
          <ArrowBackIcon />
        </IconButton>
        }
        <Box sx={(isTablet || isMobile) ? { display:'flex', justifyContent: "start", alignItems:'center', gap:2, mt:5 } : {mt:5}}>
          <img src="images/fbs_logo.png" alt="logo_main" width="100" height="100"/>
          <Box>
            <Typography fontWeight="bold" sx={{ pt: isDesktop ? 2 : 0, color: '#595959', fontStyle: 'normal' }}>
              Benefit Specialists
            </Typography>

            <Typography variant="h5" color={"primary"} sx={{ fontStyle: 'normal', mt:1 }}>
              Federal Employee
            </Typography>

            <Typography variant="h5" fontWeight="bold" sx={{ fontStyle: 'normal' }}>
              Complimentary <br/> Phone Meeting
            </Typography>
          </Box>
          { isTablet && DetailsView() }
        </Box>
        { (isMobile || isDesktop) && DetailsView() }
      </Box>
    </div>
  )
}
