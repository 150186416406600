import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box, Button, Grid } from '@mui/material';
import {NotificationManager} from 'react-notifications';

const statesList = [
    'AL', 'AZ', 'AR', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'ID', 
    'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI',
    'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NC',
    'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
    'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

export default function StateSelectDialog({
    setSelectedState,
    setStep
}) {
    const [open, setOpen] = React.useState(true);
    const [state, setState] = useState(null)

    const handleClose = () => {
        if(state) {
            setSelectedState(state)
            setOpen(false)
            setStep(1)
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box textAlign={"center"}>
                            <img src="images/calendar_icon.png" alt="calendar_icon" width="150" height="150"/>
                            <br/>
                            <br/>
                            Let's get your appointment scheduled!
                            <h3><b>First, what state are you in?</b></h3>
                            <Grid container direction="row" spacing={1} sx={{my:2, ml:1}} >
                                {
                                    statesList.map((s,index) => {
                                        return (
                                            <Grid key={index} item>
                                                <Button
                                                    variant={state === s ? "contained" : "outlined"}
                                                    style={{ display: 'flex', direction: 'row', px: 0, minWidth: '45px', width: '45px', padding:'2px' }}
                                                    value={s}
                                                    onClick={(e) => { setState(e.target.value) }} >
                                                    {s}
                                                </Button>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    { 
                        state && <Button variant="contained" onClick={handleClose} autoFocus>
                            Confirm
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
